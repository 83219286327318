@font-face {
	font-family: "VarelaRound";
	src: local("VarelaRound-Regular"),
		url("./fonts/VarelaRound-Regular.ttf") format("truetype");
}

* {
	position: relative;
}

a:not(.decorated) {
	color: #28282b;
	text-decoration: none;
}

html {
	overflow: hidden;
	height: 100%;
}

body {
	margin: 0 !important;
	padding: 0 !important;
	font-family: "VarelaRound";
	color: #28282b;
	background-color: #f5f5f5;
	overflow: auto;
	height: 100%;
}

textarea {
	resize: none;
}

/* ::-webkit-scrollbar {
	display: none;
} */

input {
	font-size: 0.8rem !important;
}

.modal-open {
	overflow: auto !important;
}

.modal-open[style] {
	padding-right: 0px !important;
}

.flex-column {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-center {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-left {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: left;
	justify-content: left;
}

.flex-row-right {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: flex-end !important;
	justify-content: flex-end !important;
}

.subflex-right {
	width: 100%;
	text-align: right;
}

a:not(.decorated) {
	text-decoration: none;
}

.white-text {
	color: #ffffff !important;
}

.default-text {
	color: #28282b !important;
}

.blue-text {
	color: #0b467d !important;
}

.dark-blue-text {
	color: #1c365f !important;
}

.bold-text {
	font-weight: bold !important;
}

.default-bg {
	background-color: #4184c3 !important;
}

.dark-bg {
	background-color: #0b467d !important;
}

.pointer {
	cursor: pointer;
}

.uppercase {
	text-transform: uppercase !important;
}

.capitalize {
	text-transform: capitalize !important;
}

.spaced {
	letter-spacing: 1px;
}

.white-bg {
	background-color: #ffffff !important;
}

.align-right {
	text-align: right !important;
}

.danger-text {
	color: #d81e5b !important;
}

.align-center {
	text-align: center !important;
}

.test {
	border: 1px solid red !important;
}

.page-section.full-page {
	min-height: 100vh !important;
	width: 100%;
}

.login-logo {
	height: 100px;
}

.tenant-login-logo {
	height: 55px;
	margin-bottom: 5px;
}

.tenant-login-brand {
	font-size: 0.8rem;
	margin-bottom: 50px;
}

.login-label {
	font-size: 1.3rem;
}

.login-brand {
	font-size: 1rem;
}

.login-text {
	border-radius: 20px;
	padding-left: 45px;
	height: 45px;
}

.section-title {
	font-size: 1.5rem;
}

.btn-md {
	height: 38px !important;
}

.btn-lg {
	height: 55px !important;
}

.btn-primary {
	outline: none !important;
	background-color: #0e5496;
	border-color: #0e5496 !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:disabled {
	background-color: #0b467d !important;
}

.btn-default {
	outline: none !important;
	background-color: #eee;
	border-color: #eee !important;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:disabled {
	background-color: #ddd !important;
}

.text-xl {
	font-size: 1.5rem !important;
}

.text-lg {
	font-size: 1.2rem;
}

.text-md {
	font-size: 1rem !important;
}

.text-sm {
	font-size: 0.95rem;
}

.label-md {
	font-size: 0.75rem !important;
}

.label-sm {
	font-size: 0.563rem;
}

.opacity-5 {
	opacity: 0.5;
}

.opacity-7 {
	opacity: 0.7;
}

.input-icon-container {
	position: absolute;
	z-index: 3;
	left: 30px;
	top: 0;
	width: fit-content;
	height: 100%;
}

.input-icon {
	font-size: 1rem;
	color: #0b467d;
}

.sidebar {
	position: fixed;
	height: 100vh;
	top: 0;
	left: 0;
	width: 250px;
	background-color: #ffffff;
	border-right: 1px solid #ddd;
	z-index: 3;
}

.tenant-sidebar {
	position: fixed;
	height: 100vh;
	top: 0;
	left: -250px;
	width: 250px;
	background-color: #ffffff;
	border-right: 1px solid #ddd;
	transition: 0.3s;
	z-index: 4;
}

.tenant-sidebar.active {
	left: 0 !important;
}

.sidebar-header {
	padding: 53px 0;
}

.tenant-sidebar-header {
	padding: 30px 0;
}

.sidebar-body {
	height: calc(100% - 160px);
	padding-bottom: 100px;
	overflow-y: auto;
}

.sidebar-logo {
	height: 55px;
}

.tenant-sidebar-logo {
	height: 45px;
}

.password-logo {
	height: 40px;
}

.sidebar-nav-item {
	display: block;
	padding: 10px 30px;
	width: 95%;
	border-radius: 0 20px 20px 0;
	margin-bottom: 10px;
	font-size: 0.9rem;
	transition: 0.2s;
}

.tenant-sidebar-nav-item {
	display: block;
	padding: 10px 10px 10px 20px;
	width: 95%;
	border-radius: 0 20px 20px 0;
	margin-bottom: 10px;
	font-size: 0.8rem;
	transition: 0.2s;
}

.sidebar-nav-item:not(.active),
.tenant-sidebar-nav-item:not(.active) {
	color: #28282b;
}

.sidebar-nav-item:not(.active):hover {
	background-color: #e9f3fb;
}

.sidebar-nav-item.active,
.tenant-sidebar-nav-item.active {
	font-weight: bold;
	color: #0e5496;
	background-color: #b3d6f7;
}

.header-user {
	position: fixed;
	top: 0;
	right: 20px;
	height: 90px;
	z-index: 3;
}

.user-icon {
	height: 45px;
	width: 45px;
	border-radius: 50%;
}

.user-icon::after,
.guest-options-btn::after {
	display: none !important;
}

.header-placeholder {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	border-bottom: 1px solid #ddd;
	background-color: #f5f5f5;
	padding-left: 300px;
	height: 90px;
}

.page-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	border-bottom: 1px solid #ddd;
	background-color: #f5f5f5;
	padding-left: 270px;
	height: 90px;
}

.search-container {
	width: 60%;
	background-color: #fff !important;
	padding-left: 20px;
	padding-right: 20px;
	height: 50px;
	border-radius: 2rem;
}

.search-text {
	background-color: transparent !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.search-icon {
	color: #6c757d;
}

.content {
	padding: 110px 20px 110px 270px;
}

.dropdown-item:focus {
	background-color: #4184c3;
	color: #fff !important;
}

.content-table {
	border-radius: 0.5rem 0.5rem 0 0;
}

.content-table tbody {
	border-top: 1px solid #ddd !important;
}

.content-table th {
	font-weight: bold !important;
	font-size: 0.9rem;
	padding: 1rem;
}

.content-table td {
	font-weight: normal !important;
	border: none !important;
	outline: none !important;
	padding: 0.9rem 1rem;
	font-size: 0.85rem;
	cursor: pointer;
	vertical-align: middle;
}

.content-table tbody tr:not(.search-tr):hover {
	background-color: #e9f3fb;
}

/* .booking-td {
	width: 20% !important;
} */

.content-header {
	height: 50px;
	width: 100%;
}

.pull-right {
	float: right;
}

.pagination-container {
	height: 100%;
	width: fit-content;
	user-select: none;
}

.pagination-label {
	font-size: 0.85rem;
}

.pagination-btn,
.pagination-btn:disabled,
.pagination-btn:focus {
	padding: 0 !important;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	background-color: transparent !important;
	box-shadow: none !important;
	border: none !important;
}

.pagination-btn:hover {
	background-color: #ddd !important;
}

.pagination-btn:active {
	background-color: #b3d6f7 !important;
}

.info-container {
	min-height: 50vh;
	border-radius: 0.5rem;
}

.form-label {
	color: #0b467d;
	font-weight: bold;
	font-size: 0.95rem;
}

.form-label:not(.inline) {
	display: block;
}

.info-col {
	box-shadow: -0.5px -0.5px 0 0.5px #eee, inset -1px -1px 0 0 #eee;
	padding: 1.3rem;
}

.page-header-col {
	box-shadow: -0.5px -0.5px 0 0.5px #eee, inset -1px -1px 0 0 #eee;
}

.tenant-info-col {
	box-shadow: -0.5px -0.5px 0 0.5px #eee, inset -1px -1px 0 0 #eee;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.tenant-info-col label,
.tenant-info-col span,
.tenant-info-col p {
	font-size: 0.9rem !important;
}

.header-row {
	background-color: #0b467d;
	box-shadow: -0.5px -0.5px 0 0.5px #0b467d, inset -1px -1px 0 0 #0b467d;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-radius: 0.25rem 0.25rem 0 0;
}

.tenant-header-row {
	background-color: #0b467d;
	box-shadow: -0.5px -0.5px 0 0.5px #0b467d, inset -1px -1px 0 0 #0b467d;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	border-radius: 0.25rem 0.25rem 0 0;
}

.nav-link {
	color: #28282b !important;
}

.nav-link.active {
	color: #0e5496 !important;
}

.action-col {
	padding: 0 !important;
}

.booking-action-btn {
	min-width: 120px;
	height: 40px !important;
	font-size: 0.75rem;
	margin-left: 15px;
}

.modal-lg .modal-body {
	min-height: 70vh;
	height: 70vh;
	overflow-y: scroll;
	overflow-x: hidden;
}

.content-loading {
	height: 70vh;
	width: 100%;
}

.scanner-loading {
	height: 50vh;
	width: 100%;
}

.modal-loading {
	height: 100%;
	width: 100%;
}

.modal-btn {
	font-size: 0.75rem;
	min-width: 120px;
	height: 40px !important;
	width: 120px;
}

.login-modal-btn {
	width: 140px !important;
}

.stat {
	height: 15vh;
}

.stat-container {
	width: 100%;
	height: 100%;
	border-radius: 0.25rem;
	padding: 0 30px;
}

.stat-val {
	font-size: 2rem;
}

.stat-label {
	font-size: 1rem;
}

.verifier-content {
	height: 70vh;
}

.vehicle-scanner-content {
	height: 70vh;
}

.scanner-body {
	height: 100%;
	width: 100%;
	border-radius: 0.25rem;
	overflow-y: auto;
}

.scanner-form input {
	width: 30vw;
}

.validity-text {
	font-size: 1.5rem;
}

.parking-modal-btn {
	width: 150px;
}

.parking-td {
	width: 20%;
}

.tenant-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
	z-index: 2;
	background-color: #1a4e7d !important;
}

.nav-toggle {
	position: absolute;
	left: 10px;
	float: left;
	height: 100%;
	width: 25px;
	z-index: 1;
	cursor: pointer;
}

.back-label {
	font-size: 1.2rem;
}

.back-btn {
	position: absolute;
	left: 10px;
	height: 100%;
	z-index: 1;
	cursor: pointer;
}

.edit-btn {
	position: absolute;
	right: 10px;
	height: 100%;
	z-index: 1;
	cursor: pointer;
}

.nav-toggle-bar {
	position: relative;
	width: 15px;
	height: 2px;
	background-color: #fff;
	border-radius: 10px;
	transition: 0.5s;
}

.nav-toggle-bar::before,
.nav-toggle-bar::after {
	content: "";
	position: absolute;
	height: 2px;
	background-color: #fff;
	border-radius: 10px;
	transition: 0.5s;
}

.nav-toggle-bar::before {
	top: -7px;
	width: 25px;
}

.nav-toggle-bar::after {
	bottom: -7px;
	width: 20px;
}

.page-title {
	font-size: 1.2rem;
}

.notif-icon {
	position: absolute;
	right: 7px;
}

.hidden {
	display: none;
}

.nav-overlay {
	position: fixed;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 3;
}

.tenant-content:not(.booking-wizard) {
	padding: 80px 10px 20px 10px;
}

.booking-wizard {
	padding: 80px 0 160px 0;
	width: 100% !important;
	margin: 0 !important;
}

.tenant-search-container {
	width: 100%;
	height: 60px;
	padding: 0 10px;
	border: 1px solid #eee;
	border-radius: 5px;
	background-color: #ffffff;
	margin-bottom: 20px;
}

.tenant-search-text {
	font-size: 0.9rem;
}

.tenent-booking {
	padding: 20px;
	border-radius: 10px;
	margin-bottom: 10px;
	padding-right: 120px;
}

.block {
	display: block !important;
}

.tenant-booking-name {
	font-size: 0.9rem;
}

.tenant-booking-info {
	font-size: 0.7rem;
}

.tenant-booking-info-container {
	padding-bottom: 150px;
}

.guest-no-icon {
	margin-top: -3px;
}

.tenant-booking-separator {
	font-size: 0.9rem;
	margin: 0 7px;
}

.tenant-booking-status-container {
	margin-top: 5px;
}

.tenant-booking-status-container > span {
	line-height: 0.7rem;
}

.tenant-booking-status {
	height: 8px;
	width: 8px;
	border-radius: 50%;
	margin-right: 5px;
}

.tenant-booking-status span {
	font-size: 0.6rem;
	text-transform: uppercase;
}

.create-btn {
	margin-left: 20px;
	font-size: 0.75rem;
	min-width: 120px;
	height: 40px !important;
	width: 120px;
	font-weight: bold;
}

.add-btn {
	position: fixed;
	z-index: 2;
	bottom: 40px;
	right: 25px;
	color: #fff !important;
	padding: 10px 13px 10px 8px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	border-radius: 20px;
	border: none !important;
	outline: none !important;
}

.add-btn-label {
	font-size: 0.9rem;
}

.add-btn-sub {
	padding-top: 15px;
	padding-bottom: 15px;
	width: 200px !important;
	font-size: 0.85rem;
}

.add-btn-sub-icon {
	margin-right: 10px;
}

.add-btn:active {
	box-shadow: none !important;
	transform: scale(0.99) !important;
}

.add-btn:focus:not(:active) {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.Snackbar_snackbar-wrapper-bottom-center__21ghq {
	bottom: calc(100vh - 120px) !important;
	z-index: 9999 !important;
}

.wizard-btn-container {
	position: fixed;
	left: 0;
	bottom: 0;
	padding: 20px 10px 20px 10px;
	border-top: 1px solid #ddd;
	width: 100%;
	background-color: #fff;
}

.wizard-btn-container .btn {
	width: 100%;
}

.wizard-input-group {
	border: 2px solid #ddd;
	border-radius: 0.5rem;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	margin-bottom: 50px !important;
}

.wizard-upload-preview {
	width: 100%;
	height: 160px;
	background-color: #eee !important;
	border-radius: 0.25rem;
	background-size: cover !important;
	background-position: center !important;
}

.wizard-upload-preview.border-danger {
	border: 1px solid red !important;
}

.wizard-upload-preview:active {
	transform: scale(0.98);
}

.lbl {
	margin-top: 5px;
	position: relative;
	height: 16px;
	width: 37px;
	background: #898989;
	border-radius: 100px;
	cursor: pointer;
	transition: all 0.3s ease;
}

.lbl::after {
	position: absolute;
	left: -2px;
	top: -3px;
	width: 22px;
	height: 22px;
	border-radius: 100px;
	background: white;
	border: 1px solid #ddd;
	box-shadow: 0px 3px 3px rgba(#000, 0.05);
	content: "";
	transition: all 0.3s ease;
}

.lbl:active::after {
	transform: scale(1.15, 0.85);
}

.cbx:checked ~ label {
	background: #c2e4fc !important;
}

.cbx:checked ~ label::after {
	left: 16px;
	background: #519efd;
	border: 1px solid #519efd;
}

.cbx:disabled ~ label {
	background: #d5d5d5;
	border: 1px solid #d5d5d5;
	pointer-events: none;
}

.cbx:disabled ~ label::after {
	background: #bcbdbc;
	border: 1px solid #bcbdbc;
}

.pool-input-group {
	box-shadow: -0.5px -0.5px 0 1px #eee, inset -1px -1px 0 0 #ddd;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	border-radius: 0 0 0.25rem 0.25rem;
}

.empty-container {
	height: 60vh;
}

.empty-container > span {
	color: #999;
}

.login-btn {
	border-radius: 30px;
}

.login-bg {
	height: 100vh;
	width: 100%;
	background: url(assets/login_bg.jpeg);
	background-size: cover !important;
	background-position: center !important;
}

.login-content {
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(11, 70, 125, 0.97);
	overflow-y: auto;
}

.login-form-container {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 50vh;
	min-height: 400px;
	width: 100%;
	border-radius: 30px 30px 0 0;
	padding: 30px 13px;
	text-align: center;
	transition: 0.5s;
}

.login-input-container {
	height: 48px;
	width: 100%;
	border-radius: 30px;
	margin-bottom: 20px;
	background-color: #fff;
	padding: 0 20px;
}

.login-input {
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	background: transparent !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	margin-top: 1px;
}

.login-form-container.expand {
	height: 100vh !important;
	border-radius: 0 !important;
}

.tenant-login-label {
	display: block;
	font-size: 0.9rem;
	margin-bottom: 25px;
}

.login-form-container.expand .tenant-login-label {
	font-size: 1rem;
}

.tenant-login-instructions {
	font-size: 0.7rem;
	padding: 0 30px;
	text-align: center;
	margin-bottom: 30px;
}

.MuiInputLabel-root:not(.MuiInputLabel-shrink) {
	font-size: 0.8rem !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
	border: 1px solid #0e5496 !important;
}

.Mui-focused.MuiInputLabel-shrink,
.MuiOutlinedInput-notchedOutline > legend > span {
	color: #0e5496 !important;
}

.MuiButton-contained {
	height: 45px;
	font-family: VarelaRound !important;
	font-size: 0.8rem !important;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: bold !important;
}

.full-height {
	height: 100% !important;
}

.full {
	width: 100% !important;
}

.half {
	width: 50% !important;
}

.tenant-login-text {
	margin-bottom: 20px !important;
}

.tenant-login-username {
	text-transform: lowercase !important;
}

.login-terms-container {
	margin-top: 30px;
}

.login-terms {
	font-size: 0.65rem;
	text-align: center;
}

.booking-img-name {
	display: block;
	font-size: 0.8rem;
	margin-bottom: 10px;
}

.booking-img {
	height: 250px;
	width: 100%;
	background-size: cover !important;
	background-position: center !important;
	border-radius: 8px;
	cursor: pointer;
}

.booking-img.verifier {
	height: 150px !important;
}

.booking-img::after,
.booking-img-sm::after,
.tenant-booking-img::after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: #000;
	border-radius: 8px;
	opacity: 0;
	transition: 0.3s;
}

.booking-img:hover::after,
.booking-img-sm:hover::after,
.tenant-booking-img:hover::after {
	opacity: 0.1;
}

.booking-img-name-sm {
	display: block;
	font-size: 0.6rem;
	margin-bottom: 10px;
}

.booking-img-sm {
	height: 150px;
	width: 100%;
	background-size: cover !important;
	background-position: center !important;
	border-radius: 8px;
	cursor: pointer;
}

.tenant-booking-tab {
	font-size: 0.9rem;
}

#toggleSwitch {
	height: 22px;
	width: 40px;
	margin-top: 0;
	box-shadow: none !important;
	cursor: pointer;
}

#toggleSwitch:checked {
	border-color: #0b467d;
	background-color: #0b467d;
}

.upload-lbl {
	font-size: 0.8rem;
	margin-top: 5px;
}

.dismiss-btn:hover,
.dismiss-btn:active {
	background-color: #ddd !important;
	color: #000 !important;
}

.form-btn-container {
	margin-top: 70px;
}

.form-btn-container .btn {
	font-size: 0.75rem;
	min-width: 120px;
	height: 40px !important;
	width: 140px;
}

.form-select {
	margin-top: 0 !important;
}

.password-text {
	padding-right: 70px;
}

.show-password-btn {
	position: absolute;
	right: 0;
	height: 100%;
	z-index: 3;
	padding: 0 10px;
	cursor: pointer;
}

.show-password-btn span {
	font-size: 0.85rem;
}

.unauthorized-logo {
	height: 100px;
	margin-bottom: 30px;
}

.verified-lbl {
	padding: 5px 10px;
	border: 1px solid #198754;
	color: #198754;
	border-radius: 7px;
	text-transform: uppercase;
	font-size: 0.7rem;
	font-weight: bold;
	margin-right: calc(50% - 180px);
}

.verifier-verified-lbl {
	border: 1px solid #fff;
	color: #fff;
	border-radius: 7px;
	text-transform: uppercase;
	font-weight: bold;
	min-width: 120px;
	font-size: 0.75rem;
	height: 40px !important;
}

.guest-group {
	padding: 0 !important;
	margin-bottom: 20px !important;
}

.guest-group-header {
	padding: 0;
	border-bottom: 1px solid #ddd;
}

.guest-group-body {
	padding-top: 1.3rem;
	padding-bottom: 2rem;
	overflow: hidden;
	transition: 0.3s;
}

.guest-group:not(.active) .guest-group-body {
	height: 0 !important;
	padding: 0 !important;
}

.guest-group:not(.active) .guest-group-header {
	border: none !important;
}

.guest-options-btn {
	padding: 0 1px !important;
	text-align: right !important;
	border-radius: 50%;
	box-shadow: none !important;
	color: #6c757d;
}

.guest-options-btn:active {
	background-color: #ddd;
}

.guest-group.active .guest-expand-icon {
	transform: rotate(180deg);
}

.guest-group-toggle {
	height: 60px;
}

.guests-header {
	font-size: 1.5rem;
	display: block;
}

.tenant-guests-header {
	font-size: 1.2rem;
	display: block;
}

.tenant-booking-img {
	height: 100px;
	background-size: cover !important;
	background-position: center !important;
}

select.form-control {
	height: 34px;
	padding-top: 0;
	padding-bottom: 0;
}

.calendar-picker {
	border: 1px solid #ddd;
	border-radius: 10px;
}

.rdrMonth {
	width: 100%;
}

.upload-progress-overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.5);
}

.upload-progress-bar {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 5px;
	background-color: #519efd;
	transition: 0.3s;
	transition-timing-function: ease-in;
	z-index: 2;
}

.scanner-logout-container {
	position: absolute;
	bottom: 0;
	padding-bottom: 30px;
}

.booking-qr {
	height: 350px;
}

.master-loader {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 10;
}

.content-loader {
	position: absolute;
	padding: 0 !important;
	margin: 0 !important;
	width: 100%;
	height: 40vh;
	border-top: none !important;
}

.booking-form-loader {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0 !important;
	margin: 0 !important;
	width: 100%;
	height: 100vh;
	border-top: none !important;
}

.suggestions-loader {
	padding: 0 !important;
	margin: 0 !important;
	width: 100%;
	height: 100px;
}

.loader-wheel {
	width: 48px;
	height: 48px;
	border: 5px solid #c6dcf6;
	border-bottom-color: #0b467d;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.content-loader .loader-wheel,
.booking-form-loader .loader-wheel,
.loader-wheel.sm {
	width: 38px;
	height: 38px;
	border-width: 3px;
}

.suggestions-loader .loader-wheel {
	width: 32px;
	height: 32px;
	border-width: 3px;
}

.empty-content {
	position: absolute;
	padding: 0 !important;
	margin: 0 !important;
	width: 100%;
	height: 40vh;
	border-top: none !important;
}

.sidebar-user {
	font-size: 0.8rem;
}

.img-modal .modal-body {
	padding: 0 !important;
	background-color: rgba(0, 0, 0, 0.8);
}

.img-open {
	max-width: 100%;
	max-height: 90vh;
	object-fit: cover;
}

.img-tools {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 3;
	padding: 20px;
}

.img-tools button {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	margin-left: 10px;
}

.img-open-container {
	height: 50vh;
	border: 1px solid red !important;
}

.report-loader {
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 3;
	width: 380px;
	border: 1px solid #eee;
	box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
	/* border-radius: 5px 5px 0 0; */
}

.report-loader-header {
	height: 40px;
	width: 100%;
	padding: 0 10px;
	background-color: #38383a;
	/* border-radius: 5px 5px 0 0; */
}

.report-loader-title {
	font-size: 0.9rem;
}

.report-loader-close {
	position: absolute;
	right: 10px;
	cursor: pointer;
}

.report-loader-body {
	width: 100%;
	max-height: 250px;
	overflow-y: auto;
}

.report-loader-item {
	padding: 25px 10px;
	width: 100%;
}

.report-loader-item:not(:last-of-type) {
	border-bottom: 1px solid #eee;
}

.report-loader-name {
	width: 70%;
	font-size: 0.8rem;
}

.report-loader-wheel {
	position: absolute;
	right: 12px;
	width: 27px;
	height: 27px;
	border: 3px solid #c6dcf6;
	border-bottom-color: #0b467d;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.report-loader-download {
	position: absolute;
	right: 10px;
	color: #0b467d;
	cursor: pointer;
	border-radius: 50%;
	border: 1px solid #eee;
	padding: 5px;
}

.report-loader-download:hover {
	background-color: #eee;
}

.report-loader-download:active {
	transform: scale(0.95);
}

.ellipsis {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.report-loader-error {
	position: absolute;
	right: 11px;
	cursor: pointer;
}

.terms-content h2 {
	font-size: 1rem;
	font-weight: bold;
}

.terms-content p {
	font-size: 0.8rem;
}

.parking-receipt {
	position: absolute;
	visibility: hidden;
	padding: 0;
	padding-top: 7px;
}

.receipt-content {
	width: 4in;
}

.receipt-content span {
	font-size: 0.6rem;
	position: absolute;
}

.receipt-guest-name {
	font-size: 0.7rem !important;
}

.revision-warning {
	display: block;
	padding: 15px 10px;
	border-radius: 10px;
	background-color: rgba(246, 195, 68, 0.8);
	margin-bottom: 30px;
}

.revision-title {
	padding: 5px 10px;
	text-transform: uppercase;
	margin-right: 10px;
	border-radius: 10px;
	background-color: rgba(246, 195, 68, 0.8);
}

.extra-guest-warning {
	background-color: rgba(245, 195, 69, 0.5);
	padding: 10px;
	border-radius: 8px;
}

.bookings-status {
	padding: 5px 10px;
	border-radius: 5px;
}

.bookings-status.pending,
.tenant-booking-status.pending {
	background-color: #5dc7ec;
}

.bookings-status.confirmed,
.tenant-booking-status.confirmed {
	background-color: #3579f6;
}

.bookings-status.checked-in,
.tenant-booking-status.checked-in {
	background-color: #53a551;
}

.bookings-status.checked-out,
.tenant-booking-status.checked-out {
	background-color: #6e757c;
}

.bookings-status.due,
.tenant-booking-status.due {
	background-color: #f6c344;
}

.bookings-status.expired,
.tenant-booking-status.expired {
	background-color: #7a43b6;
}

.bookings-status.rejected,
.bookings-status.cancelled,
.tenant-booking-status.rejected,
.tenant-booking-status.cancelled {
	background-color: #cb444a;
}

.tenant-unit,
.resident-unit {
	width: fit-content;
	display: inline-block;
	margin-right: 10px;
	border-radius: 5px;
	font-size: 0.7rem;
	padding: 3px 7px;
	margin-bottom: 5px;
}

.tenant-table > :not(:first-child) {
	border-top: 0;
}

.tenant-table thead {
	background-color: #0b467d;
	color: #fff !important;
	border: none;
}

.tenant-table tr,
.tenant-table td,
.tenant-table th {
	border: none;
}

.tenant-table > tbody > tr {
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

.tenant-table > tbody > tr:hover {
	background-color: #e9f3fb;
}

.tenant-table.units th:not(.tenant-action-col),
.tenant-table.units td:not(.tenant-action-col) {
	width: calc(100vw - 20px) !important;
	font-size: 0.85rem;
}

.tenant-table.parkings th:not(.tenant-action-col),
.tenant-table.parkings td:not(.tenant-action-col) {
	width: calc(100vw - 26px) !important;
	font-size: 0.85rem;
}

.tenant-table.spas th:not(.tenant-action-col),
.tenant-table.spas td:not(.tenant-action-col) {
	width: calc(100vw - 40px) !important;
	font-size: 0.85rem;
}

.tenant-action-col {
	width: 80px !important;
}

.tenant-form-btn {
	min-width: 120px;
	height: 35px !important;
	font-size: 0.75rem;
}

.spa-search-modal {
	min-height: 300px;
}

.spa-suggestions-container {
	position: absolute;
	width: 100%;
	left: 0;
	top: 100%;
	min-height: 100px;
	background-color: #fff;
	border: 1px solid #ddd;
	z-index: 3;
}

.suggestion {
	padding: 5px 13px;
	cursor: pointer;
}

.suggestion:hover,
.suggestion.selected {
	background-color: #e9f3fb;
}

.suggestion span {
	font-size: 0.85rem;
}

.privacy-logo {
	height: 60px;
	margin-bottom: 40px;
}

.booking-details-spa {
	margin-bottom: 5px;
}

.booking-guest {
	border-radius: 8px;
	border: 1px solid #ddd;
}

.booking-guest-header {
	border-bottom: 1px solid #ddd;
	padding: 20px;
}

.booking-guest-body {
	padding: 20px;
}

.booking-guest-info:not(:last-of-type) {
	margin-bottom: 15px;
}

.tenant-announcement {
	width: 100%;
	padding: 20px;
	border-radius: 5px;
}

.tenant-announcement-title {
	display: block;
	margin-bottom: 10px;
}

.tenant-announcement-content {
	width: 100%;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 10px;
}

.tenant-announcement-cover {
	width: 100%;
	height: 280px;
	margin-bottom: 20px;
	border-radius: 5px;
	background-size: cover !important;
	background-position: center !important;
}

.rounded-5 {
	border-radius: 5;
}

.p-20 {
	padding: 20px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-10 {
	margin-bottom: 10px;
}

.reset-password-container {
	padding: 20px;
}

.form-block-sm {
	margin-bottom: 30px;
}

.form-input {
	height: 55px;
}

.btn-lg {
	height: 48px !important;
}

.reset-password-label {
	margin: 30px 0;
}

.tenant-login-btn {
	border-radius: 30px !important;
	font-size: 1rem;
	font-weight: bold !important;
	background-color: #1470c6 !important;
	outline: none !important;
	box-shadow: none !important;
	border: none !important;
}

.tenant-login-btn:hover {
	background-color: #1167b6 !important;
}

.tenant-login-btn:focus,
.tenant-login-btn:active {
	background-color: #105da5 !important;
}

.branding {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 50px;
	font-size: 0.8rem;
}

.resident-code-btn {
	position: absolute;
	right: 10px;
	height: 28px !important;
	width: 38px;
}

.code-loader {
	width: 15px !important;
	height: 15px !important;
	border: 2px solid #c6dcf6;
	border-bottom-color: #0b467d;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.parking-overlay.active {
	display: flex;
}

.parking-overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(255, 255, 255, 0.5);
	z-index: 5;
	display: none;
}

.parking-form-logo {
	height: 60px;
}

.parking-success-container {
	padding-top: 50px;
}

.parking-success-logo {
	height: 40px;
	margin-bottom: 100px;
}

.parking-input {
	height: 48px !important;
	font-size: 1rem !important;
}

.parking-loader {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	z-index: 5;
	background-color: rgba(255, 255, 255, 0.7);
}

.photo-modal .modal-body {
	height: calc(100vh - 240px);
	overflow: hidden !important;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media print {
	.parking-receipt {
		visibility: visible !important;
	}
}
